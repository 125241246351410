
import { defineComponent } from 'vue';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
//import * as Yup from "yup";
import AttendanceUpdateDrwaer from '@/layout/header/partials/attendance/attendanceupdateDrawer.vue';
import ApiService from '@/core/services/ApiService';
import moment from 'moment';
import { VueCookieNext } from 'vue-cookie-next';
import axios from 'axios';
import { ElNotification } from 'element-plus';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'trainee-attendance',
  components: {
    AttendanceUpdateDrwaer,
  },
  data() {
    return {
      batch: {
        entity_id: '',
        tranche_id: '',
        institute_id: '',
        course_id: '',
        term_id: '',
        batch_id: '',
        total_terms: '',
      },
      opendrawer: false,
      secondaryClass: 'btn-secondary ',
      dangerClass: 'btn-danger',
      employee: {},
      batchList: [],
      termList: [],
      details: {},
      tranches_info: [],
      weekplan: {},
      entityTypes: [],
      present: '',
      late: '',
      courseList: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      trainingCalendar: [] as any,
      instituteList: [],
      componentKey: 0,
      tableData: [],
      form: {},
      moment: '' as any,
      loading: false,
      load: false,
      showcalendarDetails: false,
    };
  },
  async created() {
    this.moment = moment;
    await this.getTranches();
    await this.getEntityInfos();
    this.emitter.on('attendance-updated', async () => {
      this.calenderDetails();
    });
  },
  methods: {
    async getTranches() {
      this.loading = true;
      await ApiService.get(this.VUE_APP_TRANCHE_LIST_API)
        .then((response) => {
          this.loading = false;
          this.tranches_info = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async printPdf() {
      let entity_id = '';
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_entity_id');
      }
      let formData = new FormData();
      formData.set('batch_info_id', this.batch.batch_id);
      formData.set('term_no', this.batch.term_id);
      formData.set('course_id', this.batch.course_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('institute_id', this.batch.institute_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/trainee_attendance_report_print`,
          formData
        )
        .then((response) => {
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it
        });
    },
    async downloadPdf() {
      ElNotification({
        dangerouslyUseHTMLString: true,
        message:
          '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
      });
      let entity_id = '';
      let institute_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_id = VueCookieNext.getCookie('_entity_id');
      }
      let formData = new FormData();
      formData.set('batch_info_id', this.batch.batch_id);
      formData.set('term_no', this.batch.term_id);
      formData.set('course_id', this.batch.course_id);
      formData.set('entity_id', this.batch.entity_id);
      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('institute_id', this.batch.institute_id);
      await ApiService.post('report/trainee_attendance_report_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityInfos() {
      this.loading = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get(
        this.VUE_APP_GET_ENTITY_LIST_API + '?entity_id=' + entity_id
      )
        .then((response) => {
          this.loading = false;
          this.entityInfos = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async calenderDetails() {
      if (this.batch.term_id) {
        this.load = true;
        this.showcalendarDetails = true;

        await ApiService.get(
          this.VUE_APP_TRAINING_CALENDAR_DETAILS_API +
            '?batch_info_id=' +
            this.batch.batch_id +
            '&term_no=' +
            this.batch.term_id +
            '&course_id=' +
            this.batch.course_id +
            '&entity_id=' +
            this.batch.entity_id +
            '&tranche_id=' +
            this.batch.tranche_id +
            '&institute_id=' +
            this.batch.institute_id
        )
          .then((response) => {
            this.load = false;
            this.trainingCalendar = response.data.data;
            this.componentKey += 1;
          })
          .catch((response) => {
            this.load = false;
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Warning!',
          html: 'Please select all required fields.',
          icon: 'warning',
        });
      }
    },
    async checkAttedenceDetails(data) {
      this.loading = true;
      await ApiService.post(this.VUE_APP_TRAINEE_ATTENDANCE_COUNT, data)
        .then((response) => {
          this.present = response.data.data.present;
          this.late = response.data.data.late;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTrainingInstitute() {
      this.loading = true;
      this.batch.institute_id = '';
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.batch.term_id = '';
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      this.showcalendarDetails = true;
      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          this.loading = false;
          this.instituteList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    edit(data) {
      if (VueCookieNext.getCookie('_seip_role_id') == 1) {
        this.opendrawer = true;
        this.emitter.emit('attendance-update-drawer-info', data);
      } else {
        let todaydate = new Date();
        let training_date = new Date(data?.training_date);
        let attn_update_count = data?.attn_update_count;
        let attn_update_limit = data?.attn_update_limit;
        let attn_extended_date = data?.attn_extended_date;

        let dayDifference = Math.floor(
          (todaydate.getTime() - training_date.getTime()) /
            (1000 * 60 * 60 * 24)
        );

        let formattedTodayDate = todaydate.toISOString().split('T')[0];
        console.log(formattedTodayDate);
        console.log(attn_extended_date);
        console.log(formattedTodayDate > attn_extended_date);
        // Check if the calendar date and today , day differnece is more than 7 +15 = 22  days then block
        if (
          attn_extended_date != null &&
          formattedTodayDate > attn_extended_date
        ) {
          console.log('1st condition');
          this.opendrawer = false;
          if (data?.is_attendance_given == 1) {
            Swal.fire({
              title: "You don't have permission to update attendance!",
              html: 'Please contact with SEIP.',
              icon: 'warning',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          } else {
            console.log('1st 2nd if');
            Swal.fire({
              title: 'Attendance time is over!',
              html: 'Please contact with SEIP.',
              icon: 'warning',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        } else if (attn_update_count >= attn_update_limit) {
          console.log('2ndst condition');
          this.opendrawer = false;

          Swal.fire({
            title: 'Attendance time is over!',
            html: 'Please contact with SEIP.',
            icon: 'warning',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        } else if (dayDifference > 22 && attn_extended_date == null) {
          this.opendrawer = false;
          console.log('first time last condition');
          Swal.fire({
            title: 'Attendance time is over!',
            html: 'Please contact with SEIP.',
            icon: 'warning',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
        } else {
          this.opendrawer = true;
          this.emitter.emit('attendance-update-drawer-info', data);
          console.log('Show ok');
        }
      }
    },
    async termInfo() {
      this.batch.term_id = '';
      this.showcalendarDetails = true;
      this.trainingCalendar = [];
      this.loading = true;
      await ApiService.get(
        this.VUE_APP_GET_BATCH_TERM_API +
          '?batch_info_id=' +
          this.batch.batch_id
      )
        .then((response) => {
          this.loading = false;
          this.termList = response.data.data;
        })
        .catch((response) => {
          this.loading = false;
          console.log(response);
        });
    },
    async batchInfo() {
      this.loading = true;
      this.batch.batch_id = '';
      this.batch.term_id = '';
      this.showcalendarDetails = true;
      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_BATCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&course_info_id=' +
          this.batch.course_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.batchList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },

    async courseInfo() {
      this.loading = true;
      this.batch.course_id = '';
      this.batch.batch_id = '';
      this.batch.term_id = '';
      this.showcalendarDetails = true;
      this.trainingCalendar = [];
      await ApiService.get(
        this.VUE_APP_COURSE_SEARCH_LIST_API +
          '?entity_id=' +
          this.batch.entity_id +
          '&tranche=' +
          this.batch.tranche_id +
          '&institute_info_id=' +
          this.batch.institute_id
      )
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.courseList = response.data.data;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
  },
});
